jQuery(document).ready(function($){
    $('.slider-thumbnail').slick({
        dots: true,
        speed: 300,
        infinite: true,
        autoplay: true,
        fade: true,
        autoplaySpeed: 5000,
        cssEase: 'linear',
        arrows: false
    });
});